// Vuex state store
// See https://vuex.vuejs.org FMI on Vuex

import Vue from "vue"
import Vuex from "vuex"
import { Auth } from "aws-amplify"
import VuexPersistence from "vuex-persist"

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

const store = new Vuex.Store({

  // State store primitive objects. Don't use directly, but through
  // getters, mutations (synchronous), or actions (asynchronous)
  state: {
    userSession: null,
    selectedSearch: "users",
    personSearch: {
      query: "",
      statusBoxes: ["Available"],
      results: null
    },
    skillsSearch: {
      query: "",
      statusBoxes: ["Available"],
      skillLevel: "No Experience",
      results: null
    },
    noSkillsSearch: {
      statusBoxes: ["Available"],
      results: null
    }
  },
  plugins: [vuexLocal.plugin],

  /**
   * These getters can be accessed by mapping them in components like
   *
   *   import { mapGetters } from 'vuex'
   *   export default {
   *      // Other stuff
   *      computed: {
   *         ...mapGetters(['signedIn', 'userIsRecruiter'])
   *      },
   *      // more stuff
   *   };
   *
   * computed properties can then be used directly in templates or in
   * javascript by e.g., this.userIsRecruiter.
   */
  getters: {
    userSession: state => {
      return state.userSession
    },

    userId: state => {
      return state.userSession ?
        state.userSession.idToken.payload["cognito:username"] : null
    },

    userEmail: state => {
      return state.userSession ?
        state.userSession.idToken.payload["email"] : null
    },

    signedIn: state => {
      return !!state.userSession
    },

    userIsStaffingTeamMember: (state, getters) => {
      return getters.UserInGroup("StaffingTeam")
    },

    userIsApplicant: (state, getters) => {
      return getters.UserInGroup("Applicants")
    },

    userIsRecruiter: (state, getters) => {
      return getters.UserInGroup("Recruiters")
    },

    userIsAnler: (state, getters) => {
      return getters.signedIn &&
        getters.UserInOneOfGroups(["StaffingTeam", "Recruiters"])
    },
    userIsInAnyGroup: (state, getters) => {
      return getters.signedIn &&
        state.userSession?.idToken?.payload["cognito:groups"]?.length
    },

    UserInGroup: (state) => (group) => {
      return state.userSession?.idToken?.payload["cognito:groups"]?.includes(group) || false
    },
    UserInOneOfGroups: (state) => (groups) => {
      groups = groups || []
      const userGroups = state.userSession?.idToken?.payload["cognito:groups"] || []
      return groups.filter((group) => userGroups.includes(group)).length > 0
    },
    searchType: (state) => {
      return state.selectedSearch
    },
    personSearchProperties: (state) => {
      return state.personSearch
    },
    skillsSearchProperties: (state) => {
      return state.skillsSearch
    },
    noSkillsSearchProperties: (state) => {
      return state.noSkillsSearch
    }
  },

  // Mutations must be synchronous, and are invoked like
  // this.$store.commit('setUserSession', userSession);
  mutations: {
    setUserSession: (state, userSession) => {
      state.userSession = userSession
    },
    clearUserSession: (state) => {
      state.userSession = null
    },
    setSearchType: (state, searchType) => {
      state.selectedSearch = searchType
    },
    setPersonSearch: (state, payload) => {
      state.personSearch.query = payload.query
      state.personSearch.statusBoxes = payload.statusBoxes
      state.personSearch.results = payload.results
    },
    setSkillsSearch: (state, payload) => {
      state.skillsSearch.query = payload.query
      state.skillsSearch.statusBoxes = payload.statusBoxes
      state.skillsSearch.skillLevel = payload.skill_level
      state.skillsSearch.results = payload.results
    },
    setNoSkillsSearch: (state, payload) => {
      state.noSkillsSearch.statusBoxes = payload.statusBoxes
      state.noSkillsSearch.results = payload.results
    }
  },

  // Actions can be asynchronous and invoked with
  // this.$store.dispatch('fetchUserSession');
  actions: {
    fetchUserSession({ commit }) {
      Auth.currentSession()
        .then(session => {
          commit("setUserSession", session)
        })
        .catch((err) => {
          console.error("fetchUserSession error:", err)
          commit("clearUserSession")
        })
    }

    /*
    authorizeRequests({commit}, axiosInstance) {
       console.log('Registering interceptor');
       axiosInstance.interceptors.request.use(async (request) => {
          console.log('Request:', request);
          try {
             let userSession = await Auth.currentSession();
             commit('setUserSession', userSession);
             if (userSession)
             {
                request.headers['Authorization'] = userSession.getIdToken().getJwtToken();
             }
          }
          catch (error) {
             console.log('Error setting Authorization header:', error);
             commit('clearUserSesion');
          }
          console.log('Modified request:', request);
          return request;
       });
    },
    */
  }
})

export default store