<template>
  <div
    v-if="loaded"
    class="my-skills"
  >
    <h1>My Skills</h1>


    <p>Review the list of skills and enter your level of familiarity for each.</p>

    <b-alert
      :show="successMessage ? 5 : false"
      variant="success"
      dismissible
      fade
    >
      {{ successMessage }}
    </b-alert>

    <b-alert
      :show="submitError ? 5 : false"
      variant="danger"
      fade
    >
      {{ this.submitError }}
    </b-alert>

    <b-alert
      :show="skillError ? 10 : false"
      variant="warning"
      fade
    >
      {{ this.skillError }}
    </b-alert>

    <b-button
      v-if="hasSkills"
      variant="primary"
      :disabled="!hasChanges || saving"
      @click="SubmitSkills"
    >
      <b-spinner
        v-if="saving"
        small
        class="mb-1"
      />
      Submit
    </b-button>
    <br>
    <b>No Experience: </b>No knowledge, awareness, or experience <br>
    <b>Beginner: </b>Have done some reading or tutorials. No use in production. <br>
    <b>Intermediate: </b>Some production experience. Need to rely on outside references. <br>
    <b>Expert: </b>Significant experience, knowledge, and fluency <br>
    <div class="skills-tables">
      <template v-for="(group, groupIdx) in groupedSkills.keys()">
        <h4
          :key="group + '-heading'"
          :class="`skill-group-heading mt-3 mb-3 ${groupIdx > 0 ? ' top-border' : ''}`"
        >
          {{ group }}
        </h4>

        <b-table
          :key="group + '-table'"
          striped
          class="skills-table"
          :items="groupedSkills.get(group)"
          :fields="tableFields"
          primary-key="id"
        >
          <template v-slot:table-colgroup>
            <col
              style="width:60%;"
              class="col-skill-name"
            >
            <col
              style="width:40%;"
              class="col-skill-level"
            >
          </template>

          <template v-slot:cell(name)="data">
            <div class="skill-name-cell">
              {{ data.item.name }}
            </div>
          </template>
          <template v-slot:cell(level)="data">
            <skill-level-menu
              :skill="data.item"
              :editable="true"
              @skill-changed="UpdateSkillLevel(group, data.item, $event)"
            />
          </template>
        </b-table>
      </template>
    </div>
  </div>
  <div v-else>
    <b-spinner
      small
      class="mb-1"
    />
    Loading ...
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"

import SkillLevelMenu from "@/components/SkillLevelMenu"
import sSkillLevels from "@/assets/SkillLevels.json"

const CancelToken = axios.CancelToken

export default {
  name: "MySkills",

  components: { SkillLevelMenu },

  props: [],

  data() {
    return {
      loaded: false,
      groupedSkills: new Map(), //!< map group-name -> list of skills in that group
      hasSkills: false,         //!< true if groupedSkills has been initialized and there are skills
      hasChanges: false,
      successMessage: null,
      skillError: null,
      submitError: null,
      tableFields: ["name", "level"],
      user: {},

      skillLevels: sSkillLevels,
      saving: false
    }
  },

  computed: {

    ...mapGetters([
      "userId",
      "userEmail",
      "userIsApplicant",
      "userIsRecruiter"
    ]),

    fullName() {
      return (typeof this.user.firstName === "undefined")
        ? "user"
        : this.user.firstName + " " + this.user.lastName
    }

  },

  beforeCreate() {
    this.$get("/skills")
      .then((data) => {
        if (data && "" !== data) {
          this.groupedSkills = this.MapByGroup(data)
          this.hasSkills = true
          this.ApplyUserSkills()
        }
      })
  },

  created() {
    this.$get("/users/" + this.userId + "/skills")
      .then((data) => {
        if (data && "" !== data) {
          this.user = data
          this.ApplyUserSkills()
          this.loaded = true
        }
      })
      .catch((error) => {
        console.log(`Error fetching ${this.userId} skills:`, error)
        this.user = null
      })
  },

  methods: {

    ApplyUserSkills() {
      if (!this.hasSkills || !this.user.skills) {
        return
      }

      let missingSkillNames = []
      this.user.skills.forEach(userSkill => {
        let skills = this.groupedSkills.get(userSkill.group)
        if (skills) {
          let mainSkill = skills.find(s => s.id == userSkill.id)
          if (mainSkill) {
            mainSkill.level = this.skillLevels[userSkill.level]
          } else {
            missingSkillNames.push(userSkill.name)
          }
        }
      })

      this.skillError = null
      if (missingSkillNames.length) {
        this.skillError = "Unknown skills for user: " + missingSkillNames.join(", ")
      }
    },

    SubmitSkills() {
      if (!this.hasChanges || !this.userId || this.saving) {
        return
      }

      this.successMessage = null
      this.skillError = null
      this.submitError = null

      let params = []
      this.groupedSkills.forEach(skills => {
        skills.forEach(skill => {
          if (skill.level.id > 0) {
            params.push({ id: skill.id, level: skill.level.id })
          }
        })
      })

      if (this.cancelSubmit) {
        this.cancelSubmit()
        this.cancelSubmit = null
      }
      this.saving = true
      this.$put("/users/" + this.userId + "/skills", params,
        {
          cancelToken: new CancelToken(c => {
            this.cancelSubmit = c
          })
        })
        .then((data) => {
          this.user = data
          this.ApplyUserSkills()
          this.hasChanges = false
          this.successMessage = "Changes saved."
        })
        .catch((error) => {
          const cancelledRequest = error && true === error.__CANCEL__
          if (!cancelledRequest) {
            this.submitError = error
          }
        })
        .then(() => {
          this.cancelSubmit = null
        })
        .finally(() => {
          this.saving = false
        })
    },

    UpdateSkillLevel(group, skill, newLevel) {
      if (skill.level != newLevel) {
        skill.level = this.skillLevels[newLevel]
        this.hasChanges = true
      }
    },

    UniqueGroups(skills) {
      return [...new Set(skills.map(skill => skill.group))]
    },

    /** Rearrange the flat skill list into a dictionary with the skill groups as keys */
    MapByGroup(skills) {
      const groups = new Map(this.UniqueGroups(skills).map(group => [group, []]))

      skills.forEach((skill) => {
        groups.get(skill.group).push({
          id: skill.id,
          name: skill.name,
          level: this.skillLevels[0]
        })
      })

      return groups
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.hasChanges
      && !window.confirm("Do you really want to leave? You haven't submitted your changes.")) {
      next(false)
    } else {
      next()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

h2 {
  /**text-align:left; */
  margin: 1.5rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

</style>

<!-- Non-scoped style propagates to descendants -->
<style>
/* More compact, middle-aligned table layout */
.skills-table td, .skills-table.table th {
  text-align: left;
  vertical-align: middle;
  padding: .25rem .5rem .25rem .5rem !important;
}

.skills-table td .btn {
  margin: .25rem;
}

.skills-tables {
  max-height: calc(100vh - 15rem);
  overflow-y: scroll;
  border-bottom: 2px solid #dee2e6;
  border-top: 2px solid #dee2e6;
}

.skill-name-cell {
  margin-top: 0.65rem !important;
}

.skill-group-heading.top-border {
  padding-top: 1rem !important;
  border-top: 1px solid #dee2e6;
}

.b-table-sticky-header {
  max-height: none !important;
}


</style>
