<template>
  <div class="users">
    <router-link :to="{ name: 'Skills' }">
      All Skills
    </router-link>
    <h2>Search applicants by...</h2>

    <div v-if="userCanSearch">
      <b-tabs
        v-model="tabIndex"
        align="center"
        pills
        small
      >
        <applicant-search-tab
          :search-data="getUserSearchData()"
          :tab-active="selectedSearch() === SEARCH_TYPES.USER"
          @startSearch="startSearch"
        />

        <applicant-search-tab
          :search-data="getSkillsSearchData()"
          :tab-active="selectedSearch() === SEARCH_TYPES.SKILLS"
          @startSearch="startSearch"
        />

        <applicant-search-tab
          :search-data="getUserNoSkillsSearchData()"
          :tab-active="selectedSearch() === SEARCH_TYPES.NOSKILLS"
          @startSearch="startSearch"
        />
      </b-tabs>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"

import ApplicantSearchTab from "@/components/ApplicantSearchTab"
import store from "../store"
import { SEARCH_TYPES } from "@/utils/constants"

const CancelToken = axios.CancelToken

export default {
  name: "Applicants",

  components: {
    ApplicantSearchTab
  },

  props: {},
  data() {
    // Common search data cloned to *SearchData with spread operator
    const commonSearchData = {
      succeeded: true,
      lastRequestError: null,
      searching: false,
      searchResults: [],
      haveSearched: false,
      cancelSearch: null
    }
    return {
      SEARCH_TYPES: SEARCH_TYPES,
      userSearchData: {
        searchType: SEARCH_TYPES.USER,
        apiPath: "/users",
        tabTitle: "Name or Email",
        subtitle: "by name or email",
        ...commonSearchData
      },
      skillSearchData: {
        searchType: SEARCH_TYPES.SKILLS,
        apiPath: "/users/skillsSearch",
        tabTitle: "Skills",
        subtitle: "by skill",
        ...commonSearchData
      },
      userNoSkillsSearchData: {
        searchType: SEARCH_TYPES.NOSKILLS,
        apiPath: "/users",
        tabTitle: "No Skills Entered",
        subtitle: "with no skills",
        ...commonSearchData
      },
      user: {},

      tabIndex: 0
    }
  },

  computed: {
    // Map getters from store
    ...mapGetters(["userIsStaffingTeamMember", "userIsRecruiter"]),

    userCanSearch() {
      return this.userIsStaffingTeamMember || this.userIsRecruiter
    },

    userCanAddApplicant() {
      return this.userIsRecruiter
    }
  },

  methods: {
    arrayToSearchParams(paramName, values) {
      return values.join("&" + paramName + "=")
    },

    /**
     * \param searchData The search data corresponding to the type of
     *                   search, whose 'apiPath' element maps to the API
     *                   endpoint.
     * \param resultFunc A function that receives the `response.data`
     *                   returned from a successful request.
     */
    search(searchData, criteria, resultFunc) {
      searchData.haveSearched = true

      if (searchData.cancelSearch) {
        searchData.cancelSearch()
        searchData.cancelSearch = null
        console.log(`${searchData.apiPath}: cancelling previous search`)
      }

      searchData.searching = true
      searchData.searchResults = []

      // !!! TEMP note the ability to lose error information
      this.$get(searchData.apiPath, {
        params: criteria,
        cancelToken: new CancelToken((c) => {
          searchData.cancelSearch = c
        })
      })
        .then((data) => {
          searchData.succeeded = true
          searchData.lastRequestError = null
          searchData.searchResults = data
          switch (searchData.searchType) {
            case SEARCH_TYPES.USER:
              store.commit("setPersonSearch", {
                query: criteria.get("text"),
                statusBoxes: criteria.getAll("status"),
                results: data
              })
              break


            case SEARCH_TYPES.SKILLS:
              store.commit("setSkillsSearch", {
                query: criteria.get("text"),
                statusBoxes: criteria.getAll("status"),
                skill_level: criteria.getAll("sl"),
                results: data
              })
              break


            case SEARCH_TYPES.NOSKILLS:
              store.commit("setNoSkillsSearch", {
                statusBoxes: criteria.getAll("status"),
                results: data
              })
              break

          }
          if (resultFunc) {
            resultFunc(data)
          }
        })
        .catch((error) => {
          const cancelledRequest = error && true === error.__CANCEL__

          if (!cancelledRequest) {
            searchData.succeeded = false
            searchData.lastRequestError = error
            console.log(error)
          } else {
            console.log("cancelled search for " + searchData.apiPath)
          }
        })
        .finally(() => {
          searchData.cancelSearch = null
          searchData.searching = false
        })
    },

    startSearch(searchData, event) {
      switch (searchData.searchType) {
        case SEARCH_TYPES.USER:
          this.startPersonSearch(event)
          store.commit("setSearchType", searchData.searchType)
          break
        case SEARCH_TYPES.NOSKILLS:
          this.startPersonNoSkillsSearch(event)
          store.commit("setSearchType", searchData.searchType)
          break
        case SEARCH_TYPES.SKILLS:
          this.startSkillSearch(event)
          store.commit("setSearchType", searchData.searchType)
          break
        default:
          console.log(`Search Type ${searchData.searchType} not supported.`)
          break
      }
    },

    startPersonSearch(event) {
      let criteria = new URLSearchParams()

      if (event.statuses && 0 < event.statuses.length) {
        event.statuses.forEach((s) => {
          criteria.append("status", s)
        })
      }

      if (event.searchText && event.searchText !== "") {
        criteria.append("text", event.searchText)
      }

      // TODO: !!! Use bootstrap table's context to get page and page size
      // offset:
      // limit:
      //this.$refs.personSearchTable.isSearching = true;
      this.search(this.userSearchData, criteria, () => {
        //this.$refs.personSearchTable.isSearching = false;
      })
    },

    startPersonNoSkillsSearch(event) {
      let criteria = new URLSearchParams()

      criteria.append("noSkills", "1")

      if (event.statuses && 0 < event.statuses.length) {
        event.statuses.forEach((s) => {
          criteria.append("status", s)
        })
      }

      // TODO: !!! Use bootstrap table's context to get page and page size
      // offset:
      // limit:
      //this.$refs.personSearchTable.isSearching = true;
      this.search(this.userNoSkillsSearchData, criteria, () => {
        //this.$refs.personSearchTable.isSearching = false;
      })
    },

    startSkillSearch(event) {
      let criteria = new URLSearchParams()
      if (event.statuses && 0 < event.statuses.length) {
        event.statuses.forEach((s) => {
          criteria.append("status", s)
        })
      }
      if (event.searchText && event.searchText !== "") {
        event.searchText
          .split(",")
          .map((skill) => skill.trim() + ">=" + event.level.id)
          .forEach((skillLevel) => {
            criteria.append("sl", skillLevel)
          })

        // TODO: !!! Use bootstrap table's context to get page and page size
        // offset:
        // limit:
        //this.$refs.skillSearchTable.isSearching = true;

        this.search(this.skillSearchData, criteria, () => {
          //this.$refs.skillSearchTable.isSearching = false;
        })
      } // no search critera so just get all available applicants
      else {
        this.startPersonSearch({})
      }
    },

    getUserSearchData() {

      let searchProperties = store.getters.personSearchProperties
      this.userSearchData.searchResults = searchProperties.results ? searchProperties.results : []
      this.userSearchData.haveSearched = !!searchProperties.results

      return this.userSearchData
    },
    getUserNoSkillsSearchData() {
      let searchProperties = store.getters.noSkillsSearchProperties
      this.userNoSkillsSearchData.searchResults = searchProperties.results ? searchProperties.results : []
      this.userNoSkillsSearchData.haveSearched = !!searchProperties.results

      return this.userNoSkillsSearchData
    },
    getSkillsSearchData() {
      let searchProperties = store.getters.skillsSearchProperties
      this.skillSearchData.searchResults = searchProperties.results ? searchProperties.results : []
      this.skillSearchData.haveSearched = !!searchProperties.results

      return this.skillSearchData
    },
    selectedSearch() {
      return store.getters.searchType || SEARCH_TYPES.SKILLS
    }
  }
}
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.category {
  width: 80px;
}

.skill {
  min-width: 80px;
}

.level {
  width: 120px;
}

.notes {
  min-width: 200px;
}

.categoryCell {
  font-weight: bold;
}

table,
th,
td {
  border: 1px solid black;
}

td {
  padding: 2px;
}

#add-applicant-button {
  margin: 5px;
}

#any-status-checkbox {
  padding: 5px;
}

#standard-statuses-checkboxes {
  padding: 5px;
}
</style>
