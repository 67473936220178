<template>
  <b-table
    :id="tableId"
    striped
    small
    no-border-collapse
    :busy="isSearching"
    :fields="tableFields"
    :items="applicants"
    :sort-compare="CompareApplicants"
  >
    <template v-slot:table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle mr-2" />
        <strong class="loading-text">Loading...</strong>
      </div>
    </template>

    <template v-slot:cell(lastName)="data">
      <router-link
        :to="{ name: 'Applicant', params: { userId: data.item.id }}"
        exact
      >
        {{ data.value }}
      </router-link>
    </template>

    <template v-slot:cell(atsUrl)="data">
      <div v-if="data && data.value && data.value.length > 0">
        <a :href="data.value">Hire Profile</a>
      </div>
      <div v-else>
        None
      </div>
    </template>
  </b-table>
</template>

<script>

import excitementLevelManager from "@/utils/excitementLevels"
import formatters from "@/utils/formatters"
import store from "../store"
import { SEARCH_TYPES } from "@/utils/constants"

export default {
  name: "PersonSearchTable",

  props: {
    applicants: Array,
    isSearching: { type: Boolean, default: false },
    isForNoSkills: { type: Boolean, default: false }
  },
  data() {
    return {

      tableFields: [
        {
          key: "lastName",
          label: "Full Name",
          formatter: this.FullNameFormatter,
          sortable: true,
          class: "userSearchCell"
        },
        {
          key: "dateCreated",
          label: "Date Added",
          formatter: this.DateFormatter,
          sortable: true,
          class: "userSearchCell"
        },
        {
          key: "dateLastUpdatedByUser",
          label: "Last Updated",
          formatter: this.DateFormatter,
          sortable: true,
          class: "userSearchCell"
        },
        {
          key: "status",
          sortable: true,
          class: "userSearchCell"
        },
        {
          key: "excitementLevel",
          label: "Excitement",
          formatter: this.ExcitementFormatter,
          sortable: true,
          class: "userSearchCell"
        },
        {
          key: "atsUrl",
          label: "Hire Profile",
          class: "userSearchCell"
        }
      ]
    }
  },

  computed: {
    tableId() {
      return this.isForNoSkills ? "person-noskills-search-table" : "person-search-table"
    },
    searchProperties() {
      return (this.isForNoSkills) ? store.getters.noSkillsSearchProperties : store.getters.personSearchProperties
    }
  },
  created() {
    if (store.getters.searchType === SEARCH_TYPES.USER) {
      self.applicants = this.searchProperties.results
    } else if (store.getters.searchType === SEARCH_TYPES.NOSKILLS) {
      self.applicants = this.searchProperties.results
    }
  },

  methods: {

    FullNameFormatter(value, key, item) {
      return item.firstName + " " + item.lastName
    },

    DateFormatter(isoFormatVal) {
      return formatters.convertIsoFormattedUtcStringToLocalDateString(isoFormatVal)
    },

    ExcitementFormatter(value) {
      const level = excitementLevelManager.normalizedExcitementLevel(value)
      return excitementLevelManager.labelForExcitementLevel(level)
    },

    CompareApplicants(a, b, key, sortDesc, formatter, compareOptions, compareLocale) {
      if ("dateCreated" == key || "dateLastUpdatedByUser" == key) {
        return ((b == a) ? 0 : ((a < b) ? 1 : -1))
      } else if ("lastName" == key) {
        // Sort by last name, first
        const astr = `${a["lastName"]}_${a["firstName"]}`
        const bstr = `${b["lastName"]}_$(b['firstName']}`
        return astr.localeCompare(bstr, compareLocale, compareOptions)
      }
      return null  // Default sorting
    }
  }
}
</script>

<style>

.userSearchCell {
  text-align: start;
}

.b-table-sticky-header {
  max-height: none !important;
}


</style>