<template>
  <div>
    <router-link :to="{ name: 'Skills' }">
      All Skills
    </router-link>
    <router-link :to="{ name: 'Applicants' }">
      Applicants
    </router-link>

    <h1>Add Applicant</h1>

    <b-form @submit.stop.prevent="Submit">
      <b-form-group id="fullname-input-group">
        <b-form-row id="fullname-row">
          <b-col style="padding: 0 5px 0 0">
            <b-form-input
              id="firstname-input"
              v-model.trim="$v.form.firstName.$model"
              style="padding: 10px"
              :state="Validate($v.form.firstName)"
              placeholder="First name"
            />

            <b-form-invalid-feedback :state="Validate($v.form.firstName)">
              First name is required.
            </b-form-invalid-feedback>
          </b-col>

          <b-col style="padding: 0 0 0 5px">
            <b-form-input
              id="lastname-input"
              v-model.trim="$v.form.lastName.$model"
              style="padding: 10px"
              :state="Validate($v.form.lastName)"
              placeholder="Last name"
            />

            <b-form-invalid-feedback :state="Validate($v.form.lastName)">
              Last name is required.
            </b-form-invalid-feedback>
          </b-col>
        </b-form-row>
      </b-form-group>

      <b-form-group id="email-input-group">
        <b-form-row>
          <b-form-input
            id="user-email-input"
            v-model.trim="$v.form.emailAddress.$model"
            :state="Validate($v.form.emailAddress)"
            placeholder="Email address"
          />
        </b-form-row>

        <b-form-invalid-feedback :state="Validate($v.form.emailAddress)">
          Enter a valid email address.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="atsurl-input-group">
        <b-form-row>
          <b-form-input
            id="google-hire-field"
            v-model.trim="$v.form.atsUrl.$model"
            :state="Validate($v.form.atsUrl)"
            placeholder="Google Hire URL"
          />
        </b-form-row>

        <b-form-invalid-feedback :state="Validate($v.form.atsUrl)">
          Enter a valid Google Hire URL.
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="status-input-group">
        <b-form-row id="status-row">
          <b-col>
            Status:
            <status-menu
              :applicant="this.form"
              @status-changed="SetStatus($event)"
            />

            Excitement Level:
            <excitement-level-menu
              :applicant="this.form"
              @excitement-level-changed="SetExcitementLevel($event)"
            />
          </b-col>
        </b-form-row>
      </b-form-group>

      <b-form-row>
        <b-form-textarea
          id="applicant-notes-field"
          v-model="$v.form.notes.$model"
          placeholder="Notes"
        />
      </b-form-row>

      <b-alert
        :show="this.lastRequestError"
        :fade="true"
        variant="danger"
      >
        {{ lastRequestError }}
      </b-alert>

      <b-button
        id="submit-button"
        type="submit"
        variant="primary"
        :disabled="this.$v.form.$invalid || saving"
      >
        <b-spinner
          v-if="saving"
          small
          class="mb-1"
        />
        Submit
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { email, required, url } from "vuelidate/lib/validators"

import StatusMenu from "@/components/StatusMenu"
import ExcitementLevelMenu from "@/components/ExcitementLevelMenu"

export default {

  name: "AddApplicant",

  components: { StatusMenu, ExcitementLevelMenu },

  mixins: [validationMixin],

  props: {},

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        atsUrl: "",
        status: "Available",
        notes: "",
        excitementLevel: null
      },
      lastRequestError: null,
      saving: false
    }
  },

  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      emailAddress: { required, email },
      atsUrl: { url },
      notes: {}
    }
  },

  methods: {
    SetStatus(val) {
      this.form.status = val
    },

    SetExcitementLevel(val) {
      this.form.excitementLevel = val
    },

    /** \returns Object suitable for form state, if it exists, otherwise, null */
    Validate(formVar) {
      return formVar.$dirty ? !formVar.$error : null
    },

    /** \returns True if form data is valid, false otherwise. */
    CheckValidations() {
      this.$v.form.$touch()
      return !this.$v.form.$anyError
    },

    Submit() {
      if (this.saving) {
        return
      }
      if (this.CheckValidations()) {
        this.saving = true
        this.lastRequestError = null
        this.$post("/users", this.form)
          .then((data) => {
            // On success go to the Applicant page with a message.
            const userObj = data
            userObj.skills = []
            this.$router.push({
              name: "Applicant",
              params: {
                newAppplicant: userObj,
                userId: userObj.id,
                successMessage: `Applicant ${userObj.firstName} ${userObj.lastName} was added.`
              }
            })
          })
          .catch((error) => {
            this.lastRequestError = String(error)
          })
          .finally(() => {
            this.saving = false
          })
      } else {
        this.lastRequestError = "Please correct the errors indicated above."
      }
    }
  }
}

</script>

<style scoped>
.form-row {
  margin: 10px;
}
</style>
