<template>
  <b-form
    :id="idPrefix+'search-form'"
    class="mt-3 mb-4 pl-5 pr-5"
    @submit.stop.prevent="CheckEmitPersonSearch"
  >
    <b-form-group class="pl-5 pr-5">
      <b-row
        v-if="showTextField"
        no-gutters
        align-h="center"
      >
        <b-col cols="8">
          <b-form-input
            :id="idPrefix+'search-text'"
            v-model="searchFormText"
            class="mb-2"
            placeholder="Enter full or partial name or e-mail address"
            @keydown.enter.native="CheckEmitPersonSearch"
          />
        </b-col>
      </b-row>

      <b-row
        no-gutters
        align-h="center"
      >
        <b-col>
          <b-checkbox-group
            id="standard-statuses-checkboxes"
            v-model="selectedFormStatuses"
            :options="statuses"
            button-variant="light"
            inline
            text-field="name"
            value-field="name"
            plain
          />
        </b-col>
      </b-row>

      <b-row
        no-gutters
        align-h="center"
      >
        <b-col>
          <b-button
            :id="idPrefix+'search-submit'"
            size="sm"
            :disabled="!hasSearchCriteria || searching"
            type="submit"
          >
            <b-spinner
              v-if="searching"
              small
              class="mb-1"
            />
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
  </b-form>
</template>

<script>
import sStatuses from "@/assets/Statuses.json"
import store from "../store"

export default {
  name: "PersonSearchForm",

  props: {
    isForNoSkills: { type: Boolean, default: false },
    searching: { type: Boolean, default: false }
  },

  data() {
    return {
      statuses: sStatuses,
      anyStatusText: "Any Status",
      searchingPerson: false,
      selectedFormStatuses: ["Available"],
      searchFormText: ""
    }
  },

  created() {
    this.selectedFormStatuses = this.selectedStatuses
    this.searchFormText = this.searchText
  },
  computed: {
    showTextField() {
      return !this.isForNoSkills
    },
    hasSearchCriteria() {
      return (
        (!this.showTextField) ||
        (this.searchFormText && "" !== this.searchFormText) ||
        this.selectedFormStatuses.length > 0
      )
    },
    idPrefix() {
      return this.isForNoSkills ? "person-noskills-" : "person-"
    },
    searchProperties() {
      return (this.isForNoSkills) ? store.getters.noSkillsSearchProperties : store.getters.personSearchProperties
    },
    selectedStatuses() {
      return this.searchProperties.statusBoxes || ["Available"]
    },
    searchText() {
      return this.searchProperties.query || ""
    }

  },
  methods: {
    CheckEmitPersonSearch() {
      // TODO: Filter statuses for any-status and conflicting statuses?
      if (this.hasSearchCriteria && !this.searching) {
        this.$emit("person-search", {
          searchText: this.searchFormText,
          statuses: this.selectedFormStatuses
        })
      }
    }
  }
}
</script>

