// Confguration docs:
// https://aws-amplify.github.io/docs/js/authentication#manual-setup

import axios from "axios"


export function getCognitoValues() {
  return axios.get("/cognitoExports.json")
}

