<template>
  <b-dropdown
    v-if="editable"
    :id="`${skill.name.toLowerCase().replace(' ', '-')}-level-dropdown`"
    :text="skill.level.name"
    lazy
    class="skill-level-menu"
  >
    <template v-for="level in skillLevels">
      <b-dd-item-button
        :key="level.id"
        v-b-tooltip:hover.right.ds125="level.description"
        class="skill-level-menu-item"
        @click="$emit('skill-changed', level.id)"
      >
        {{ level.name }}
      </b-dd-item-button>
    </template>
  </b-dropdown>

  <div v-else>
    {{ skill.level.name }}
  </div>
</template>

<script>
import skillLevelDef from "../assets/SkillLevels"

export default {
  name: "SkillLevelMenu",

  props: {
    "skill": Object,
    "editable": Boolean
  },

  data() {
    return {
      skillLevels: skillLevelDef
    }
  }
}
</script>

<style scoped>

</style>
