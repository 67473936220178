// Authorize axios requests to backend API, with the help of the
// VueAxios plugin https://www.npmjs.com/package/vue-plugin-axios

import { Auth } from "aws-amplify"
import axios from "axios"
import store from "@/store"

const vueAxiosConfig = {
  axios,
  config: {
    baseURL: process.env.VUE_APP_API_URL
  },
  interceptors: {
    async beforeRequest(config /*, axiosInstance */) {
      let vxState
      try {
        vxState = localStorage.getItem("vuex")
        vxState = JSON.parse(vxState)
      } catch (e) {
        console.error("Failed to load cached vuex state")
      }
      const now = Math.round(Date.now() / 1000)

      if (vxState?.userSession?.idToken?.payload?.exp >= now) {
        store.commit("setUserSession", vxState.userSession)
        const userSession = vxState.userSession
        config.headers.Authorization = userSession.idToken.jwtToken
      } else {
        try {
          const userSession = await Auth.currentSession()
          store.commit("setUserSession", userSession)
          if (userSession) {
            config.headers.Authorization = userSession.getIdToken().getJwtToken()
          }
        } catch (error) {
          console.log("Error setting Authorization header:", error)
          store.commit("clearUserSession")
        }
      }
      return config
    }
  }
}

export default vueAxiosConfig
