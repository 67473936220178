<template>
  <b-form
    id="skill-search-form"
    class="mt-3 mb-4 pl-5 pr-5"
    @submit.stop.prevent="CheckEmitSkillSearch"
  >
    <b-form-group class="pl-5 pr-5">
      <b-row
        no-gutters
        align-h="center"
      >
        <b-col cols="8">
          <b-form-input
            id="skill-search-text"
            v-model="searchText"
            placeholder="Enter full or partial skill names, separated by commas"
            @keydown.enter.native="CheckEmitSkillSearch"
          />
        </b-col>
      </b-row>

      <b-row
        no-gutters
        align-h="center"
      >
        <b-col>
          <b-checkbox-group
            id="standard-statuses-checkboxes"
            v-model="selectedStatuses"
            :options="statuses"
            button-variant="light"
            inline
            text-field="name"
            value-field="name"
            plain
          />
          <b-row>
            <b-col
              cols="6"
              class="text-right pr-0"
            >
              <label class="skill-label">Skill Level &#8805;</label>
              <!-- less than equal -->
            </b-col>
            <b-col
              cols="6"
              class="text-left pl-0"
            >
              <b-dropdown
                id="skill-levels"
                :text="this.skillSearchLevel.name"
                toggle-class="mb-0"
                size="sm"
              >
                <template v-for="level in skillLevels">
                  <b-dd-item-button
                    :key="level.id"
                    class="skill-levels-item"
                    :class="{
                      active: skillSearchLevel === level,
                    }"
                    @click="skillSearchLevel = level"
                  >
                    {{ level.name }}
                  </b-dd-item-button>
                </template>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row
        no-gutters
        align-h="center"
      >
        <b-col>
          <b-button
            id="skill-search-submit"
            size="sm"
            :disabled="!hasSkillCriteria || searching"
            type="submit"
          >
            <b-spinner
              v-if="searching"
              small
              class="mb-1"
            />
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
  </b-form>
</template>

<script>
import sSkillLevels from "@/assets/SkillLevels.json"
import sStatuses from "@/assets/Statuses.json"
import store from "../store"

export default {
  name: "SkillSearchForm",

  props: {
    searching: { type: Boolean, default: false }
  },
  data() {
    return {
      skillLevels: sSkillLevels,

      skillSearchLevel: sSkillLevels[0],
      searchText: "",
      statuses: sStatuses,
      selectedStatuses: ["Available"]
    }
  },

  computed: {
    hasSkillCriteria() {
      return this.searchText && "" !== this.searchText
    }
  },
  created() {
    let searchProperties = store.getters.skillsSearchProperties
    this.selectedStatuses = searchProperties.statusBoxes
    this.searchText = this.buildQuery(searchProperties.skillLevel)
    this.skillSearchLevel = sSkillLevels[this.getSkillLevel(searchProperties.skillLevel)]
  },

  methods: {
    CheckEmitSkillSearch() {
      if (this.hasSkillCriteria && !this.searching) {
        this.$emit("skill-search", {
          searchText: this.searchText,
          level: this.skillSearchLevel,
          statuses: this.selectedStatuses
        })
      }
    },
    buildQuery(skillLevelList) {
      let skill_names = []
      skillLevelList.forEach((skill) => {
        skill_names.push(skill.split(">=")[0])
      })
      return skill_names !== [] ? skill_names.join(",") : ""
    },
    getSkillLevel(skillLevelList) {
      let skill_level = 0
      skillLevelList.forEach((skill) => {
        skill_level = parseInt(skill.split(">=")[1]) || 0
      })
      return skill_level
    }
  }
}
</script>

<style scoped>
.skill-label {
  padding-top: 11px;
}
</style>