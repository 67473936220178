import Vue from "vue"
import BootstrapVue from "bootstrap-vue"
import App from "./App.vue"
import router from "./router"
import Amplify, { Auth, Hub } from "aws-amplify"
import { getCognitoValues } from "./awsconfig"
import store from "./store.js"
import VueAxios from "vue-plugin-axios"
import vueAxiosConfig from "./vueAxiosConfig.js"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

getCognitoValues().then((response) => {
  let client_id
  let userpool_id
  let domain
  userpool_id = response?.data?.VUE_APP_COGNITO_USERPOOL_ID
  client_id = response?.data?.VUE_APP_COGNITO_CLIENT_ID
  domain = response?.data?.VUE_APP_COGNITO_APP_DOMAIN


  Amplify.configure({
    Auth: {
      region: process.env.VUE_APP_AWS_REGION,
      userPoolId: userpool_id,
      userPoolWebClientId: client_id,
      oauth: {
        domain: domain,
        scope: ["email", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: process.env.VUE_APP_BASE_URL,
        redirectSignOut: process.env.VUE_APP_BASE_URL,
        responseType: "code"
      }
    }
  })
})


Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Auth)
Vue.use(Hub)
Vue.use(VueAxios, vueAxiosConfig)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
