<template>
  <div class="main">
    <router-link :to="{ name: 'Main' }">
      Main
    </router-link>

    <h1>Authorization Test Page</h1>

    <b-form @submit="onSubmit">
      <b-form-group id="userTest">
        <b-form-row>
          Get User
        </b-form-row>
        <b-form-row>
          <b-form-radio
            v-model="form.userEndpoint"
            name="userEndpoint"
            value=""
          >
            Profile
          </b-form-radio>
          <b-form-radio
            v-model="form.userEndpoint"
            name="userEndpoint"
            value="/skills"
          >
            Skills
          </b-form-radio>
        </b-form-row>
        <b-form-row>
          for ID / Username:
        </b-form-row>
        <b-form-row>
          <b-form-input
            id="otherUserId-input"
            v-model.trim="form.otherUserId"
          />
          URL: {{ url }}<br>
          Result: {{ serverResponse }}
        </b-form-row>
      </b-form-group>

      <b-button
        type="submit"
        variant="primary"
      >
        Submit
      </b-button>
    </b-form>
  </div>
</template>

<script>

//import axios from 'axios';

export default {
  name: "TestAuthorization",
  props: {},

  data() {
    return {
      serverResponse: null,
      url: null,
      form: {
        otherUserId: "",
        userEndpoint: "" // '' or '/skills'
      }
    }
  },

  methods: {
    onSubmit() {
      this.url = "/users/" + this.form.otherUserId + this.form.userEndpoint
      this.$get(this.url).then((data) => {
        this.serverResponse = data
      })
        .catch((error) => {
          this.serverResponse = error
        })
    }
  }
}

</script>

<style scoped>
</style>