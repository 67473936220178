<template>
  <b-dropdown
    id="status-level-dropdown"
    :text="this.applicant.status"
    lazy
  >
    <template v-for="status in statuses">
      <b-dd-item-button
        :key="status.id"
        @click="$emit('status-changed', status.name)"
      >
        {{ status.name }}
      </b-dd-item-button>
    </template>
  </b-dropdown>
</template>

<script>
import sStatuses from "@/assets/Statuses.json"

export default {
  name: "StatusMenu",

  props: {
    "applicant": Object,
    "hasAnyStatus": { type: Boolean, default: false }
  },

  data() {
    return {
      statuses: sStatuses
      // See create(), might add Any Status item
    }
  },

  created() {
    if (this.hasAnyStatus) {
      const anyId = 1 + this.statuses.reduce((maxId, status) => Math.max(maxId, status.id), 1)
      this.statuses.push({ "id": anyId, "name": "Any Status" })
      console.log("Added Any Status item with id " + anyId)
    }
  },

  methods: {

    StatusName(index) {
      return index != -1 ? this.statuses[index].name : "Any Status"
    }
  }
}
</script>
