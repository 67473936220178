<template>
  <div>
    <template v-for="g in groups">
      <b-table
        :key="g.name + '-table'"
        striped
        :fields="Fields(g)"
        :items="g.skills"
        class="skills-display-table"
      >
        <template v-slot:cell(name)="data">
          {{ data.item.name }}
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>

export default {
  name: "SkillsDisplay",

  props: {
    groups: Array
  },

  data() {
    return {}
  },

  methods: {
    Fields(group) {
      return [{ key: "name", label: group.name }]
    }
  }

}

</script>

<style scoped>
table, th, td {
  border: 1px solid black;
}

td {
  padding: 2px;
}

.b-table-sticky-header {
  max-height: none !important;
}


</style>